import React from 'react';
import { Col, Form, Layout, Row, Table } from 'antd';
import {
    Button,
    List
} from '@presentation/components/form';
import { columns } from './columns';
import ConcilitorFilters from './components/filters';
import ConciliatorReadValues from './components/readValues';
import { ConciliationTotal, ConciliatorFilter, IConciliator } from '@/@core/modules/conciliator/domain/conciliator.entity';
import conciliator from '@/@core/modules/conciliator/infra/container.registry';

interface IParams {
    paymentType: 'CREDIT_CARD' | 'BANK_SLIP_AND_PIX',
    initialTransactionDate: string,
    finalTransactionDate: string,
    adquirente: string,
    companyName: string,
    bank: string,
}

const ConcilitorList = () => {
    const [loading, setLoading] = React.useState(false);
    const [loadingProcess, setLoadingProcess] = React.useState(false);
    const [modalVisible, setModalVisible] = React.useState(false);
    const [params, setParams] = React.useState<IParams>();

    const [reload, setReload] = React.useState(false);

    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const [selectedItem, setSelectedItem] = React.useState<IConciliator[] | []>([]);

    const handleProcess = async () => {
        setLoadingProcess(true);
        await conciliator.process.execute(selectedItem!.map(item => item.idConciliation)).then(() => {
            setSelectedItem([]);
            setSelectedRowKeys([]);
            loadData({ paymentType: selectedPaymentType });
            setLoadingProcess(false);
        }
        ).catch(() => { setLoadingProcess(false) }
        ).finally(() => { setLoadingProcess(false) }
        )
    }

    const [totals, setTotals] = React.useState<ConciliationTotal>({} as ConciliationTotal);
    const [data, setData] = React.useState<IConciliator[]>([]);

    const [selectedPaymentType, setSelectedPaymentType] = React.useState<"CREDIT_CARD" | "BANK_SLIP_AND_PIX" | "SELECT">('SELECT');



    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: IConciliator[]) => {
            setSelectedItem(selectedRows);
            setSelectedRowKeys(selectedRowKeys);
        },
    };

    const [form] = Form.useForm();

    async function loadData(params: ConciliatorFilter | any) {
        setLoading(true);
        if (params.paymentType === 'SELECT') {
            return;
        }

        const result = await conciliator.getList.execute(params);

        setTotals(result.totals);
        setLoading(false);
        setData(result.data);
        return result;
    }

    const handleLoadData = async () => {
        const values = form.getFieldsValue();

        if (selectedPaymentType === 'SELECT') {
            return;
        }
        setParams({
            paymentType: selectedPaymentType,
            initialTransactionDate: values.initialTransactionDate,
            finalTransactionDate: values.finalTransactionDate,
            adquirente: values.adquirente ? values.adquirente : values.bank,
            companyName: values.companyName,
            bank: values.bank,
        });
        loadData(params);
    }

    React.useEffect(() => {
        if (selectedPaymentType === 'SELECT') {
            setParams(undefined);
        } else {
            setParams({
                paymentType: selectedPaymentType,
                initialTransactionDate: '',
                finalTransactionDate: '',
                adquirente: '',
                companyName: '',
                bank: '',
            });
            loadData(params);
        }
    }, [selectedPaymentType]);

    return (
        <Layout data-testid="layout-administration">
            <ConcilitorFilters selectedPaymentType={selectedPaymentType} setSelectedPaymentType={setSelectedPaymentType} form={form} />
            {
                selectedPaymentType !== 'SELECT' && (
                    <>
                        <ConciliatorReadValues selectedPaymentType={selectedPaymentType} total={totals} />
                        <Row style={{ marginBottom: 20, borderRadius: 8 }}>
                            <Col span={24}>
                                <List
                                    loadData={loadData}
                                    columns={columns}
                                    setLoading={setLoading}
                                    loading={loading}
                                    setModalVisible={setModalVisible}
                                    modalVisible={modalVisible}
                                    setParams={setParams}
                                    params={params}
                                    isReload={reload}
                                    setReload={setReload}
                                    showStatusBillingFilter={false}
                                    showStatusFilter={false}
                                    hideDataFilter={true}
                                    rowKey='idConciliation'
                                    showPagination={false}
                                    rowSelection={rowSelection}
                                    data={data}
                                />
                                {/* <Table
                                    style={{ borderRadius: 8 }}
                                    dataSource={data}
                                    columns={columns}
                                    pagination={false}
                                    rowSelection={rowSelection}
                                    bordered
                                    size="small"
                                /> */}
                            </Col>
                        </Row>

                        <Col span={24} style={{ textAlign: 'right', marginBottom: '20px' }}>
                            <Row gutter={16} justify="end">
                                <Button
                                    type="primary"
                                    disabled={selectedItem.length === 0}
                                    onClick={() => handleProcess()}
                                    loading={loadingProcess}
                                    nativeProps={{
                                        style: { margin: '20px 15px 20px 0' }
                                    }}
                                    text='Processar'
                                />
                                <Button
                                    type="primary"
                                    onClick={() => handleLoadData()}
                                    loading={loading}
                                    nativeProps={{
                                        style: { margin: '20px 0' }
                                    }}
                                    text='Atualizar Data'
                                />
                            </Row>
                        </Col>
                    </>
                )
            }

        </Layout>
    );
};

export default ConcilitorList;
