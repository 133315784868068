import { AxiosInstance } from "axios";
import { conciliatorGateway } from "../domain/conciliator.gateway";
import { paths } from "./keys";
import { ConciliationResponse, ConciliatorFilter } from "../domain/conciliator.entity";

export class conciliatorHttpGateway implements conciliatorGateway {
  constructor(private http: AxiosInstance) { }

  async process(ids: number[]): Promise<void> {
    return await this.http.post<void>(paths.process, {
      ids,
    }).then((response) => response.data);
  }

  async getListConciliator(filters: ConciliatorFilter): Promise<ConciliationResponse> {
    return await this.http.get(paths.getListConciliator, {
      params: filters,
    }).then((response) => response.data);
  }
}